import React from 'react';
import {useTranslation} from "gatsby-plugin-react-i18next";
import {PublishedArticles} from "../../util/constant";

const AboutOhada = ({}: any) => {

    const {t} = useTranslation()

    return (
        <>
            <section className="wrapper bg-light angled lower-end">
                <div className="container py-14 py-md-16">
                    <div className="row gx-lg-8 gx-xl-12 gy-10 mb-4 mb-md-7 align-items-start">
                        <div className="col-lg-4 position-relative order-lg-2">
                            <div className="shape bg-dot primary rellax w-16 h-20" data-rellax-speed="1"
                                 style={{top: "3rem", left: "5.5rem"}}></div>
                            <div className="overlap-grid overlap-grid-2">
                                <div className="item">
                                    <figure className="rounded shadow"><img src="/assets/img/truth.jpg"
                                                                            srcSet="/assets/img/truth.jpg 2x"
                                                                            alt=""/></figure>
                                </div>
                                <div className="item">
                                    <figure className="rounded shadow"><img src="/assets/img/editor4.jpg"
                                                                            srcSet="/assets/img/editor4.jpg 2x"
                                                                            alt=""/></figure>
                                </div>
                            </div>
                        </div>

                        <aside className="col-lg-3 sidebar mt-8 mt-lg-5 position-relative order-lg-3">
                            <div className="widget">
                                <form className="search-form">
                                    <div className="form-floating mb-0">
                                        <input id="search-form" type="text" className="form-control"
                                               placeholder="Search"/>
                                        <label htmlFor="search-form">{t('Search')}</label>
                                    </div>
                                </form>
                            </div>
                            <div className="widget">
                                <h4 className="widget-title mb-3">{t('news')}</h4>
                                <div className="swiper-container dots-start dots-closer mt-md-10 mb-md-15"
                                     data-margin="30"
                                     data-dots="true" data-autoplay="true" data-autoplaytime="2000">
                                    <div className="swiper">
                                        <div className="swiper-wrapper">
                                            <div className="swiper-slide align-middle">
                                                <a href="#actuality" className="pe-auto">
                                                    <div
                                                        className="card bg-soft-primary card-border-bottom border-primary">
                                                        <div className="card-body">
                                                            <h5>{t('callForSub')}</h5>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="swiper-slide align-middle">
                                                <a href="/app/submission" className="pe-auto">
                                                    <div
                                                        className="card bg-soft-primary card-border-bottom border-primary">
                                                        <div className="card-body">
                                                            <h6>{t('submission')}</h6>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="swiper-slide">
                                                <div
                                                    className="card p-1 bg-soft-primary card-border-bottom border-primary">
                                                    <div className="card-body">
                                                        <blockquote className="icon fs-lg">
                                                            <p className="fs-14">“
                                                                {t('memberReactionMessage2')}
                                                                <a href='/app/submission/'>{t('subGuiChar')} </a>
                                                                ”</p>
                                                        </blockquote>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="swiper-slide">
                                                <div
                                                    className="card p-1 bg-soft-primary card-border-bottom border-primary">
                                                    <div className="card-body">
                                                        <blockquote className="icon fs-lg">
                                                            <h6>
                                                                <a href='/app/contact/'>{t('meetOhadarbTeam')}</a>
                                                            </h6>
                                                        </blockquote>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <nav className="nav social">
                                    <a href="https://www.linkedin.com/company/ohadarbitration" target="_blank" rel="noreferrer"><i className="uil uil-twitter"></i></a>
                                    <a href="https://www.linkedin.com/company/ohadarbitration" target="_blank" rel="noreferrer"><i className="uil uil-facebook-f"></i></a>
                                    <a href="https://www.linkedin.com/company/ohadarbitration" target="_blank" rel="noreferrer"><i className="uil uil-linkedin"></i></a>
                                    <a href="https://www.linkedin.com/company/ohadarbitration" target="_blank" rel="noreferrer"><i className="uil uil-instagram"></i></a>
                                    <a href="https://www.linkedin.com/company/ohadarbitration" target="_blank" rel="noreferrer"><i className="uil uil-youtube"></i></a>
                                </nav>
                            </div>
                        </aside>

                        <div className="col-lg-5">
                            <img src="/assets/img/icons/lineal/megaphone.svg"
                                 className="svg-inject icon-svg icon-svg-md mb-4" alt=""/>
                            <h2 className="display-4 mb-3">{t('whoAreWe')}</h2>
                            <p className="lead fs-lg ">{t('aboutOhadaSectionSubTitle')}</p>
                            <p className="mb-6 text-justify"> {t('aboutOhadaSectionP1')}</p>
                            <div className="row gy-3 gx-xl-8">
                                <div className="col-xl-12">
                                    <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                        <li><span><i className="uil uil-check"></i></span><span>{t('objective1')}</span>
                                        </li>
                                        <li className="mt-3"><span><i
                                            className="uil uil-check"></i></span><span>{t('objective2')}</span>
                                        </li>
                                    </ul>
                                </div>

                                <div className="col-xl-12">
                                    <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                        <li><span><i className="uil uil-check"></i></span><span>{t('objective3')}</span>
                                        </li>
                                        <li className="mt-3"><span><i
                                            className="uil uil-check"></i></span><span>{t('objective4')}</span>
                                        </li>
                                    </ul>
                                </div>

                            </div>

                        </div>

                    </div>

                    <div className="row gx-lg-8 gx-xl-12 gy-10 mb-5 mb-md-7 align-items-start">
                        <div>
                            <div className="col-md-10 col-xl-8 col-xxl-7 mx-auto text-center">
                                <img src="/assets/img/icons/lineal/list.svg"
                                     className="svg-inject icon-svg icon-svg-md mb-4" alt=""/>
                                <h2 className="display-4 mb-4 px-lg-14">{t('OhadarbitrationSectionTitle')}</h2>
                            </div>
                        </div>

                        <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-start">
                            <div className="col-lg-5 order-lg-2">
                                <div className="card me-lg-6">
                                    <div className="card-body p-6">
                                        <div className="d-flex flex-row">
                                            <div>
                                            <span
                                                className="icon btn btn-circle btn-lg btn-soft-primary pe-none me-4"><span
                                                className="number">01</span></span>
                                            </div>
                                            <div>
                                                <h4 className="mb-1">{t('OhadarbitrationPart1')}</h4>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="card ms-lg-13 mt-6">
                                    <div className="card-body p-6">
                                        <div className="d-flex flex-row">
                                            <div>
                                            <span
                                                className="icon btn btn-circle btn-lg btn-soft-primary pe-none me-4"><span
                                                className="number">02</span></span>
                                            </div>
                                            <div>
                                                <h4 className="mb-1">{t('OhadarbitrationPart2')}</h4>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="card mx-lg-6 mt-6">
                                    <div className="card-body p-6">
                                        <div className="d-flex flex-row">
                                            <div>
                                            <span
                                                className="icon btn btn-circle btn-lg btn-soft-primary pe-none me-4"><span
                                                className="number">03</span></span>
                                            </div>
                                            <div>
                                                <h4 className="mb-1">{t('OhadarbitrationPart3')}</h4>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <aside className="col-lg-3 sidebar mt-8 mt-lg-5 position-relative order-lg-3">
                                <div className="widget">
                                    <h4 className="widget-title mb-3">{t('Popular Posts')}</h4>
                                    <ul className="image-list">
                                        {PublishedArticles({t})
                                            .map((it)=>{
                                            return (
                                                <li key={it.id}>
                                                    <figure className="rounded">
                                                        <a href={it.link}>
                                                            <img src={it.imgUrl} alt={it.title}/>
                                                        </a>
                                                    </figure>
                                                    <div className="post-content">
                                                        <h6 className="mb-2">
                                                            <a className="link-dark" href={it.link}>
                                                            {it.title}
                                                        </a></h6>
                                                        <ul className="post-meta">
                                                            <li className="post-date">
                                                                <i className="uil uil-calendar-alt"></i>
                                                                <span>{new Date(it.date).toLocaleDateString()}</span>
                                                            </li>
                                                            <li className="post-comments">
                                                                <a href={it.link}>
                                                                    <i className="uil uil-comment"></i>
                                                                    {it.comments}
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                                <div className="widget">
                                    <h4 className="widget-title mb-3">Categories</h4>
                                    <ul className="unordered-list bullet-primary text-reset">
                                        <li><a
                                            href="/app/nationalLaw?type=Bilateral-Investment-Treaties&country=Mali">{t('nationalLaw')}</a>
                                        </li>
                                        <li><a href="/app/ohadarbLaws/">{t('ohadarbLaws')}</a></li>
                                        <li><a href="/app/ccjaCaseLaw/">{t('CCJACaseLaw')}</a></li>
                                        <li><a href="#actuality">{t('callForSub')} </a></li>
                                        <li><a href="/app/submission">{t('documentation')} (3)</a></li>
                                        <li><a href="/contact">{t('aboutUs')} (14)</a></li>
                                    </ul>
                                </div>
                            </aside>
                            <div className="col-lg-4">
                                <h2 className="display-6 mb-3">{t('howItWorks')}</h2>
                                <p className="lead fs-lg pe-lg-5">{t('howItWorksP1')}</p>
                                {/*<p>*/}
                                {/*    /!*{t('howItWorksP21')} *!/*/}
                                {/*</p>*/}
                                {/*<p className="mb-6"> {t('howItWorksP31')}*/}
                                {/*    <a href="https://www.ohadarbitration.com/"> {t('ohadarbSite')}</a>*/}
                                {/*    {t('howItWorksP32')} <a*/}
                                {/*        href="mailto:journal@ohadarbitration.com"> {t('howItWorksP2Link')}  </a>*/}
                                {/*</p>*/}
                                <ul>
                                    <li>{t('createAccount')}</li>
                                    <ul>
                                        <li>{t('legislationOHADA')}</li>
                                        <li>{t('legislationNational')}</li>
                                        <li>{t('treatiesBiAndMulti')}</li>
                                        <li>{t('caseLawDetails')}</li>
                                    </ul>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <ul>
                                <li>{t('subscriptionDetails')}</li>
                                <li>
                                    {t('submissionDetails')}
                                    <a href="mailto:journal@ohadarbitration.com">{t('howItWorksP2Link')}  </a>
                                    {/*{t('howItWorksP22')}*/}
                                </li>
                            </ul>
                            <a href="/contact" className="btn btn-primary rounded-pill mb-0">{t('learnMore')}</a>
                        </div>

                    </div>

                </div>

            </section>

        </>
    )
}
export default AboutOhada;
