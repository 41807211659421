import React, {useEffect, useState} from "react";
import AboutTeamSection from "./AboutTeamSection";
import JournalSection from "./JournalSection";
import AwardSection from "./AwardSection";
import MemberReaction from "./MemberReaction";
import AboutOhada from "./AboutOhada";
import HomeSlider from "./HomeSlider";
import Actuality from "./Actuality";
import {useTranslation} from "gatsby-plugin-react-i18next";
import ActualitySlider from "./ActualitySlider";
import {getArticles, getCcjaCaseLaws, getNationalLaws} from "../../queries";
import {OhadaTeams} from "../../util/constant";
import {scrollToTargetDiv} from "../../util/helper";
// import EditorialTeam from "./EditorialTeam";

const HomeContent = () => {
    const {t, i18n} = useTranslation()
    const [articles, setArticles] = useState<[]>([])
    const [ohadArbLaws, setOhadArbLaws] = useState<[]>([])
    const [ccjaCaseLaws, setCcjaCaseLaws] = useState<[]>([]);
    const [teams, setTeams] = useState<any[]>([])

    // const isMobile = useMediaQuery('(max-width: 850px)')
    useEffect(() => {
        const res = OhadaTeams({t})
        setTeams(res)
        getArticles(setArticles, i18n.language)
        getNationalLaws(setOhadArbLaws, i18n.language)
        getCcjaCaseLaws(setCcjaCaseLaws, i18n.language)
        scrollToTargetDiv("Home")
        return () => {
            setTeams([])
            setArticles([])
            setOhadArbLaws([])
        }
    }, [i18n.language]);
    return <>
        <div>
            <HomeSlider/>
            {/*{*/}
            {/*    !isMobile && <ActualitySlider />*/}
            {/*}*/}
            <ActualitySlider />
            <AboutOhada/>
            <MemberReaction/>
            {/*<EditorialTeam />*/}
            <AboutTeamSection teams={teams}/>
            <JournalSection/>
            <Actuality/>
            <AwardSection
                    articlesNumber={articles?.length}
                    treatiesNumber={ohadArbLaws
                        ?.filter((item: any) => item.type.includes('Treaties'))
                        ?.length}
                    caseLawsNumber={ccjaCaseLaws?.length}
            />
        </div>
    </>
}

export default HomeContent;
