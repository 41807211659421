import React from 'react';
import {useI18next} from "gatsby-plugin-react-i18next";

const HomeSlider = ({}: any) => {
    const {t} = useI18next()


    return (
        <>
            <section className="wrapper bg-dark" id="Home">
                <div className="swiper-container swiper-hero dots-over" data-margin="0" data-autoplay="true"
                     data-autoplaytime="7000" data-nav="true" data-dots="true" data-items="1">

                    <div className="swiper">

                        <div className="swiper-wrapper">

                            <div className="swiper-slide bg-overlay bg-overlay-400 bg-dark bg-image"
                                 data-image-src="/assets/img/bg2.jpg">
                                <div className="container h-100">
                                    <div className="row h-100">
                                        <div
                                            className="col-md-10 offset-md-1 col-lg-7 offset-lg-0 col-xl-6 col-xxl-5 text-center text-lg-start justify-content-center align-self-center align-items-start">
                                            <h3 className="display-1 fs-56 mb-4 text-white animate__animated animate__slideInDown animate__delay-1s">
                                                {t('slidingText1')}
                                            </h3>
                                            <p className="lead fs-23 lh-sm mb-7 text-white animate__animated animate__slideInRight animate__delay-2s">
                                                {t('slidingSubText1')}
                                            </p>
                                            <div className="animate__animated animate__slideInUp animate__delay-3s"><a
                                                href="/app/ohadarbJournal"
                                                className="btn btn-lg btn-outline-white rounded-pill">{t('readMore')}</a>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>

                            <div className="swiper-slide bg-overlay bg-overlay-400 bg-dark bg-image"
                                 data-image-src="/assets/img/editor2.png">
                                <div className="container h-100">
                                    <div className="row h-100">
                                        <div
                                            className="col-md-11 col-lg-8 col-xl-7 col-xxl-6 mx-auto text-center justify-content-center align-self-center">
                                            <h3 className="display-1 fs-56 mb-4 text-white animate__animated animate__slideInDown animate__delay-1s ">
                                                {t('slidingText1')}
                                            </h3>
                                            <p className="lead fs-23 lh-sm mb-7 text-white animate__animated animate__slideInRight animate__delay-2s">
                                                {t('slidingSubText1')}
                                            </p>
                                        </div>

                                    </div>

                                </div>

                            </div>

                            <div className="swiper-slide bg-overlay bg-overlay-400 bg-dark bg-image"
                                 data-image-src="/assets/img/ohadarb2.png">
                                <div className="container h-100">
                                    <div className="row h-100">
                                        <div
                                            className="col-md-10 offset-md-1 col-lg-7 offset-lg-5 col-xl-6 offset-xl-6 col-xxl-5 offset-xxl-6 text-center text-lg-start justify-content-center align-self-center align-items-start">
                                            <h2 className="display-1 fs-56 mb-4 text-white animate__animated animate__slideInDown animate__delay-1s "> {t('meetOhadarbTeam')}</h2>
                                            <p className="lead fs-23 lh-sm mb-7 text-white animate__animated animate__slideInRight animate__delay-2s ">
                                                {t('slidingSubText1')}</p>
                                            <div className="animate__animated animate__slideInUp animate__delay-3s"><a
                                                href="/contact"
                                                className="btn btn-lg btn-outline-white rounded-pill">{t('contactUs')}</a>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </section>
        </>
    )
}
export default HomeSlider;

