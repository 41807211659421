import React from 'react'
import {useTranslation} from "gatsby-plugin-react-i18next";

const Actuality = ({}: any) => {
    const { t } = useTranslation()

    return (
        <section className="wrapper bg-gradient-primary" id="actuality">
            <div className="container py-14 pt-md-15 pb-md-18">
                <div className="row text-center">
                    <div className="col-md-10 col-lg-12 col-xxl-11 mx-auto" data-cues="zoomIn" data-group="welcome"
                         data-interval="-200">
                        <h1 className="display-1 mb-4">{t('news')}</h1>
                        <h2 className="display-1 fs-26 mb-4">{t('callForSub')}</h2>
                        <p className="lead fs-22 lh-sm px-md-5 px-xl-15 px-xxl-10 mb-7"> {t('howItWorksP0')}</p>
                        <p className="lead fs-20 lh-sm px-md-5 px-xl-15 px-xxl-10 mb-7 text-justify">
                            {t('howItWorksP21')}
                            <a href="mailto:journal@ohadarbitration.com">{t('howItWorksP2Link')}  </a>
                            {t('howItWorksP22')} {t('howItWorksP221')}
                        </p>
                        <p className="lead fs-20 lh-sm px-md-5 px-xl-15 px-xxl-10 mb-7 text-justify">
                            {t('howItWorksP331')}
                            <a href="https://www.ohadarbitration.com/"> {t('ohadarbSite')}</a>
                            {t('howItWorksP332')} <a href="mailto:journal@ohadarbitration.com"> {t('howItWorksP2Link')}  </a>
                        </p>
                    </div>
                </div>
                <div className="d-flex justify-content-center" data-cues="slideInDown" data-group="join"
                     data-delay="900">
                    <span><a className="btn btn-lg btn-primary rounded-pill mx-1 text-wrap" href="/app/ohadarbJournal" > {t('viewAllNews')}</a></span>
                    <span><a className="btn btn-lg btn-outline-primary rounded-pill mx-1 text-wrap" href="/contact">{t('contactUs')}</a></span>
                </div>
                <div className="row mt-12" data-cue="fadeIn" data-delay="1600">
                    <div className="col-lg-8 mx-auto">
                        <h4 className="text-center d-block">Thèse de Monsieur Reagan INTOLE</h4>
                        <figure><img className="img-fluid" src="/assets/img/teams/soutenance-reagan.jpg"
                                     style={{objectFit: 'cover', objectPosition: 'center', borderRadius: '10px',
                                         boxShadow: '0 0 10px 0 rgba(0,0,0,.15)', maxWidth: '70%', maxHeight: 'auto',
                                         width: '70%', height: 'auto', margin: 'auto', display: 'block', position: 'relative'}}
                                     srcSet="/assets/img/teams/soutenance-reagan.jpg 2x" alt="soutenance reagan"/></figure>
                        <p className="text-center d-block mt-4 fs-20 text-justify">
                            « La responsabilité des grandes entreprises en matière sociétale et
                            environnementale à l’aune du devoir de vigilance. (Analyse comparée des
                            droits français et OHADA) »
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}


export default Actuality;
