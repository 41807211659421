import React from 'react';
import {useTranslation} from "gatsby-plugin-react-i18next";

const AwardSection = ({articlesNumber, treatiesNumber, caseLawsNumber}:any) => {
    const {t} = useTranslation()
    return (
        <>
            <section className="wrapper bg-soft-primary angled lower-start">
                <div className="container py-14 py-md-16">
                    <div className="row">
                        <div className="col-xl-10 mx-auto">
                            <div className="row align-items-center counter-wrapper gy-3 text-center">
                                <div className="col-md-4">
                                    <img src="/assets/img/icons/lineal/check.svg"
                                         className="svg-inject icon-svg icon-svg-lg text-primary mb-3" alt=""/>
                                    <h3 className="counter">{caseLawsNumber}</h3>
                                    <p>{t('CaseLaw')}</p>
                                </div>

                                <div className="col-md-4">
                                    <img src="/assets/img/icons/lineal/user.svg"
                                         className="svg-inject icon-svg icon-svg-lg text-primary mb-3" alt=""/>
                                    <h3 className="counter">{articlesNumber.toString()}</h3>
                                    <p>Articles</p>
                                </div>

                                <div className="col-md-4">
                                    <img src="/assets/img/icons/lineal/briefcase-2.svg"
                                         className="svg-inject icon-svg icon-svg-lg text-primary mb-3" alt=""/>
                                    <h3 className="counter">{treatiesNumber}</h3>
                                    <p>{t('Traitees')}</p>
                                </div>

                                {/*<div className="col-md-3">*/}
                                {/*    <img src="/assets/img/icons/lineal/award-2.svg"*/}
                                {/*         className="svg-inject icon-svg icon-svg-lg text-primary mb-3" alt=""/>*/}
                                {/*    <h3 className="counter">4</h3>*/}
                                {/*    <p>Awards</p>*/}
                                {/*</div>*/}

                            </div>

                        </div>

                    </div>

                </div>

            </section>

        </>
    )
}

export default AwardSection;
