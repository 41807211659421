import React from 'react'
import {useTranslation} from "gatsby-plugin-react-i18next";
import {EditorialTeam} from "../../util/constant";


const MemberReaction = ({}: any) => {
    const {t, i18n} = useTranslation()

    return (
        <>
            <section className="wrapper bg-soft-primary">
                <div className="container pt-16 pb-14 pb-md-0">

                    <div className="row gx-lg-8 gx-xl-0 align-items-center">
                        <h2 className="display-1 mb-4">{t('EditorialTeam')}</h2>
                        <div className="swiper-container mt-md-10 mb-md-15" data-margin="30"
                             data-dots="true" data-autoplay="true" data-autoplaytime="4000" data-items="1">
                            <div className="swiper">
                                <div className="swiper-wrapper">
                                    {EditorialTeam({t}).map((member: any) => {
                                        if (i18n.language === "fr" && member.id === 2) {
                                            return null
                                        }else if(i18n.language === "en" && member.id === 1){
                                            return null
                                        }
                                        return (
                                            <div key={member.id}
                                                className="swiper-slide d-flex flex-row justify-content-around align-items-center">
                                                <div className="container h-100">
                                                    <div className="row h-100">
                                                        <div
                                                            className="col-md-5 col-lg-5 col-xl-4 offset-xl-1 d-none d-md-flex position-relative align-self-end">
                                                            <div
                                                                className="shape rounded-circle bg-pale-primary rellax w-21 h-21 d-md-none d-lg-block"
                                                                data-rellax-speed="1" style={{top: "7rem", left: "1rem"}}></div>
                                                            <figure>
                                                                <img src={member.imgUrl}
                                                                     style={{width: "50vw", marginRight: '20px'}} alt=""/>
                                                            </figure>
                                                        </div>

                                                        <div
                                                            className="col-md-7 col-lg-6 col-xl-6 col-xxl-5 offset-xl-1 align-self-center">
                                                            <blockquote className="icon fs-lg">
                                                                <p>“{member.message}”</p>
                                                                <div className="blockquote-details">
                                                                    <div className="info ps-0">
                                                                        <h5 className="mb-1">{member.name}</h5>
                                                                        <p className="mb-0">{member.title}</p>
                                                                    </div>
                                                                </div>
                                                            </blockquote>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )})
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


export default MemberReaction
