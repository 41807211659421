import React, {useEffect, useState} from "react";
import {useTranslation} from "gatsby-plugin-react-i18next";
import {getConnected} from "../../util/helper";
import {getArticles} from "../../queries";
import CardType3 from "../card/CardType3";

const JournalSection = () => {
    let connected = getConnected()
    const [user, setUser] = useState(connected || null)
    const [selected, setSelected] = useState<any>(null)
    const [articles, setArticles] = useState<any>([])
    const {t, i18n} = useTranslation()
    useEffect(() => {
        getArticles(setArticles, i18n.language)
        return () => {
            setArticles([])
            setSelected(null)
        };
    }, []);

    return (
        <section className="wrapper bg-light">
            <div className="container py-14 py-md-16">
                <div className="row gx-lg-8 gx-xl-12 gy-10 gy-lg-0">
                    <div className="col-lg-4 mt-lg-2">
                        <h2 className="display-4 mb-3">{t('ourJournal')}</h2>
                        <p className="lead fs-lg mb-6 pe-xxl-5">{t('journalSectionP1')}</p>
                        <a href="/app/ohadarbJournal" className="btn btn-soft-primary rounded-pill">{t('viewAllNews')}</a>
                    </div>
                    <div className="col-lg-8">
                        <div className="swiper-container blog grid-view mb-6 mr-6"  data-autoplay="true" data-margin="30" data-dots="true"
                             data-items-md="1" data-items-xs="1">
                            <div className="swiper">
                                <div className="swiper-wrapper col-lg-8">
                                    {articles
                                        ?.sort((a: any, b: any) => a.updatedAt < b.updatedAt ? 1 : -1)
                                        ?.filter((it: any, id: number) => id <= 10)
                                        ?.map((article: any, id: number) => {
                                            return (

                                                <div key={id} className="swiper-slide w-80 px-10">
                                                    <CardType3
                                                        // className="w-75"
                                                        title={article?.title.toString()}
                                                        description={new Date(article.updatedAt).toLocaleDateString()}
                                                        link={`${location.pathname + 'app/ohadarbJournal/' + article.id}`}
                                                        getSelected={(id: number) => {
                                                            setSelected(articles.filter((article: any) => article.id === id)[0])
                                                        }}
                                                        summary={article.summary}
                                                        authors={article.authors?.split('__') || []}
                                                        lawKey={article.id}
                                                        disabledActions={true}
                                                    />
                                                </div>
                                            )
                                        })}
                                    <div className="swiper-slide">
                                        <article>
                                            <figure className="overlay overlay-1 hover-scale rounded mb-5"><a
                                                href="/app/ohadarbJournal">
                                                <img src="/assets/img/teams/soutenance-reagan.jpg"
                                                     style={{maxWidth: "90%", maxHeight: "auto"}} alt=""/></a>
                                                <figcaption>
                                                    <h5 className="from-top mb-0">{t('readMore')}</h5>
                                                </figcaption>
                                            </figure>
                                            <div className="post-header">
                                                <div className="post-category text-line">
                                                    <a href="/app/ohadarbJournal" className="hover"
                                                       rel="category">Thèse</a>
                                                </div>
                                                <h2 className="post-title h3 mt-1 mb-3"><a className="link-dark"
                                                                                           href="/app/ohadarbJournal">
                                                    Thèse de Monsieur Reagan INTOLE</a></h2>
                                            </div>
                                            <div className="post-footer">
                                                <ul className="post-meta mb-0">
                                                    <li className="post-date"><i
                                                        className="uil uil-calendar-alt"></i><span>08 Mar 2023</span>
                                                    </li>
                                                    <li className="post-comments"><a href="/app/ohadarbJournal"><i
                                                        className="uil uil-comment"></i>4</a></li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default JournalSection;
