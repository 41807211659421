import React, {useState} from 'react';
import {getConnected} from "../../util/helper";
import {useTranslation} from "gatsby-plugin-react-i18next";

const CardType3 = ({
                       title,
                       description,
                       link,
                       lawKey,
                       summary,
                       getSelected,
                       authors,
                       disabledActions
                   }: { title: string, description: string, link: string, lawKey: string, authors: [], summary: string, getSelected: any, disabledActions?: boolean }) => {


    const {t} = useTranslation()
    let connected = getConnected()
    const [user, _] = useState(connected || null)

    return (
        <div className="card card-border-bottom border-blue border-width-2 m-2 min-vw-80 w-100 max-vw-60" key={lawKey}
            style={{
                width: '45%',
                minHeight: '310px',
            }}
        >
            { !disabledActions && user?.id && user?.roles[0].name === 'ADMIN' &&
            <div className="card-header p-6">
                <div className="card-header-tabs text-end">
                    <span className="badge bg-pale-ash">
                        <div className="d-flex flex-column justify-content-end nav-item dropdown"
                             style={{cursor: "pointer"}}>
                            <i className="uil uil-ellipsis-h text-primary fs-20 text-purple"
                               data-bs-toggle="dropdown" data-toggle="tooltip" data-placement="bottom"
                               title="Action">
                            </i>
                            <div className="dropdown-menu dropdown-menu-start"
                                 style={{right: "0", top: "3em", left: 'auto'}}>
                                <div className="dropdown-item"
                                     style={{cursor: 'pointer'}}
                                     data-bs-target="#modal-addJournal"
                                     data-bs-toggle="modal"
                                     onClick={() => {
                                         getSelected(lawKey)
                                     }}>
                                    <p className="dropdown-item-text"> {t('Edit')} </p>
                                </div>
                                <div className="dropdown-item"
                                     style={{cursor: 'pointer'}}
                                     onClick={() => {
                                         getSelected(lawKey)
                                     }}
                                     data-bs-target="#modal-delete"
                                     data-bs-toggle="modal">
                                    <p className="dropdown-item-text text-red"> {t('Delete')} </p>
                                </div>
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            }
            <a className="text-decoration-none" href={link}>
                <h5 className="card-title my-2 mx-3">{title}</h5>
                <div className="card-header-pills m-0">
                    <span className="badge bg-aqua">{lawKey}</span>
                </div>
                <div className="card-body px-4 py-0">
                    <p className="card-text align-items-start text-start text-truncate text-wrap pl-3 overflow-auto"
                       style={{maxHeight: 65, height: 65}}
                    > {summary}</p>
                    <div className="d-flex flex-row justify-content-between align-items-start">
                        <p className="card-text align-items-start text-start text-truncate pl-3"> {t('published on')} : {description} </p>
                        <i className="font-weight-bold fs-15">{t('Authors')}</i>
                    </div>
                </div>
            </a>
                    <div className="card-footer d-flex flex-row justify-content-end">
                        <div className="d-flex flex-row justify-content-start float-left overflow-auto text-decoration-none flex-nowrap"
                                style={{
                                    maxWidth: '100%',
                                    scrollbarWidth: 'thin',

                        }}
                        >
                            {
                                authors.map((author: any, id: number) => {
                                    return (
                                        <i key={id} className="fw-bold bg-pale-ash fs-15 mx-1 px-1 rounded-4 mw-20 text-nowrap">{author}</i>
                                    )
                                })
                            }
                        </div>
                    </div>
        </div>
    )
}


export default CardType3;
