import React, { useEffect} from 'react';
import {navigate} from "gatsby"

const AboutTeamSection = ({teams}: any) => {
    const handleClick = (e: { preventDefault: () => void; }, path: string) => {
        navigate(path)
    }

    return (
        <section className="wrapper bg-light" id="team-ohadarb">
            <div className="container py-14 py-md-16">
                <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
                    {/*<div className="col-lg-4">*/}
                    {/*    <h2 className="fs-15 text-uppercase text-line text-primary text-center mb-3">{t('meetTheTeam')}</h2>*/}
                    {/*    <h3 className="display-5 mb-5">{t('aboutSectionSubTitle')}</h3>*/}
                    {/*    <p>{t('aboutSectionP1')}</p>*/}
                    {/*    <a href="/app/contact" className="btn btn-primary rounded-pill mt-3">{t('meetOhadarbMember')}</a>*/}
                    {/*</div>*/}
                    <div className="col-lg-12">
                        <div className="swiper-container text-center mb-6" data-margin="30"
                             data-dots="true"
                             data-autoplay="true"
                                data-loop="true"
                             data-items-xl="4" data-items-md="4" data-items-xs="4"
                        >
                            <div className="swiper">
                                <div className="swiper-wrapper">
                                    {teams
                                        ?.map((team: any) => {
                                            return (
                                                <div className="swiper-slide col-lg-4"
                                                     style={{cursor: 'pointer'}}
                                                     key={team.name}
                                                     onClick={(e) => handleClick(e, `/about#${team?.name.toLocaleLowerCase().split(' ').join('-')}`)}>
                                                    <img className="rounded-circle w-20 mx-auto mb-4"
                                                         src={team.imgURL || "/assets/img/teams/mod/avatar.png"}
                                                         alt={team.name}/>
                                                    <h4 className="mb-1">{team.name}</h4>
                                                    <div className="meta mb-2">{team.title}</div>
                                                    <p className="mb-2">{team.ShortDescription}</p>
                                                    <nav className="nav social justify-content-center text-center mb-0">
                                                        <a href="#"><i className="uil uil-twitter"></i></a>
                                                        <a href="#"><i className="uil uil-slack"></i></a>
                                                        <a href="https://www.linkedin.com/company/ohadarbitration" target="_blank" rel="noreferrer"><i className="uil uil-linkedin"></i></a>
                                                    </nav>
                                                </div>
                                            )
                                        })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutTeamSection;
