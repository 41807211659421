import React, {useState} from "react"
import {useTranslation} from 'gatsby-plugin-react-i18next';
import HomeContent from "../components/home/HomeContent";
import {useEffect} from "react";
import Layout from "../components/layout/Layout";
import 'swiper/css';
import {loadScript} from "../util/helper";
import {graphql} from "gatsby";

const IndexPage = () => {

    const {t} = useTranslation();
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        loadScript("/assets/js/plugins.js");
        setLoading(true)
        const timer = setTimeout(() => {
            loadScript("/assets/js/theme.js");
        }, 1000)
        return () => {
            clearTimeout(timer);
            setLoading(false)
        }
    }, [loading])

    return (
        <>
            <Layout title={t('HomeTitle')}>
                {!loading && <div className="page-loader"></div>}
                <HomeContent/>
            </Layout>
        </>
    )
}

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

