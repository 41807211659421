import React from 'react';
import {useTranslation} from "gatsby-plugin-react-i18next";

const ActualitySlider = ({}:any) => {
    const {t} = useTranslation()

    return (
        <>
            <div className="row">
                <div className="d-flex flex-row justify-content-end" >
                    <div className="widget position-absolute top-0 my-5 w-100  zindex-md-999 py-12 px-8">
                        <div className="swiper-container dots-start dots-closer mt-md-10 mb-md-10" data-margin="30"
                             data-dots="true" data-autoplay="true" data-autoplaytime="2000">
                            <div className="swiper">
                                <div className="swiper-wrapper">

                                    <div className="swiper-slide align-middle">
                                        <a href="#actuality" className="pe-auto">
                                            <div className="card bg-soft-primary card-border-bottom border-primary">
                                                <div className="card-body p-2 ">
                                                    <div
                                                        className="d-flex flex-row justify-content-between align-items-center">
                                                        <h6 className="fs-16 fw-bold">{t('news')}</h6>
                                                        <h6 className="fs-16">le 08 Mars 2023</h6>
                                                    </div>
                                                    <h5 className="fs-sm-12">Thèse de Monsieur Reagan INTOLE</h5>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="swiper-slide align-middle">
                                        <a href="#actuality" className="pe-auto">
                                            <div className="card bg-soft-primary card-border-bottom border-primary">
                                                <div className="card-body p-2 ">
                                                    <div
                                                        className="d-flex flex-row justify-content-between align-items-center">
                                                        <h6 className="fs-16 fw-bold">{t('news')}</h6>
                                                        <h6 className="fs-16">le 10 février 2023</h6>
                                                    </div>
                                                    <h5 className="fs-sm-12">{t('callForSub')}</h5>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="swiper-slide align-middle">
                                        <a href="/app/submission" className="pe-auto">
                                            <div className="card bg-soft-primary card-border-bottom border-primary">
                                                <div className="card-body p-2">
                                                    <div
                                                        className="d-flex flex-row justify-content-between align-items-center">
                                                        <h6 className="fs-16 fw-bold">{t('news')}</h6>
                                                        <h6 className="fs-16">le 17 février 2023</h6>
                                                    </div>
                                                    <h6>{t('submission')}</h6>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="card p-1 bg-soft-primary card-border-bottom border-primary">
                                            <div className="card-body p-2">
                                                <blockquote className="icon fs-lg">
                                                    <div
                                                        className="d-flex flex-row justify-content-between align-items-center">
                                                        <h6 className="fs-16 fw-bold">{t('news')}</h6>
                                                        <h6 className="fs-16">le 17 février 2023</h6>
                                                    </div>
                                                    <p className="fs-14 fs-sm-10">“
                                                        {t('memberReactionMessage2')}
                                                        <a href='/app/submission/'>{t('subGuiChar')} </a>
                                                        ”</p>
                                                </blockquote>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="swiper-slide">
                                        <div className="card p-1 bg-soft-primary card-border-bottom border-primary">
                                            <div className="card-body p-2">
                                                <blockquote className="icon fs-lg">
                                                    <div
                                                        className="d-flex flex-row justify-content-between align-items-center">
                                                        <h6 className="fs-16 fw-bold">{t('news')}</h6>
                                                        <h6 className="fs-16">le 17 février 2023</h6>
                                                    </div>
                                                    <h6 className="fs-sm-10">
                                                        <a href='/contact/'>{t('meetOhadarbTeam')}</a>
                                                    </h6>
                                                </blockquote>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ActualitySlider;
